/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import markdownContent from './MARKDOWN.md';

const App = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(markdownContent)
      .then((response) => response.text())
      .then((text) => setContent(text))
      .catch((error) => console.error('Error fetching the markdown file:', error));
  }, []);

  return (
    <div className="min-h-screen">
      <div className="max-w-4xl mx-auto bg-white p-6">
        <ReactMarkdown
          children={content}
          remarkPlugins={[remarkGfm]}
          components={{
            h1: ({ node, ...props }) => <h1 className="text-4xl font-bold my-4" {...props} />,
            h2: ({ node, ...props }) => <h2 className="text-3xl font-semibold my-4" {...props} />,
            h3: ({ node, ...props }) => <h3 className="text-2xl font-medium my-4" {...props} />,
            p: ({ node, ...props }) => <p className="my-2 text-gray-700" {...props} />,
            a: ({ node, ...props }) => <a className="text-blue-500 hover:underline" {...props} />,
            blockquote: ({ node, ...props }) => <blockquote className="border-l-4 border-gray-300 pl-4 italic my-4" {...props} />,
            code: ({ node, ...props }) => <code className="bg-gray-200 rounded p-1" {...props} />,
            pre: ({ node, ...props }) => <pre className="bg-gray-800 text-white p-4 rounded my-4 overflow-x-auto" {...props} />,
            img: ({ node, ...props }) => <img className="my-4 mx-auto" {...props} />,
            ul: ({ node, ...props }) => <ul className="list-disc list-inside my-2" {...props} />,
            ol: ({ node, ...props }) => <ol className="list-decimal list-inside my-2" {...props} />,
          }}
        />
      </div>
    </div>
  );
};

export default App;
